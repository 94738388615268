<template>
  <div>
    <Loader v-if="isCheckingScenarios && !scenario" class="py-6"/>
    <template v-else>
      <GMAlert class="mt-3" icon="check" type="success">
        {{ $t('verifyUser.VERIFICATION_COMPLETE') }}
      </GMAlert>
      <p class="mt-3">
        {{ $t('verifyUser.THANKS_FOR_VERIFYING') }}
      </p>
      <div v-if="scenario">
        <template v-if="(scenario === ScenarioEnum.ONE_TO_ONE || !isFirstTimeVerification)">
          <p class="mt-3">
            {{ $t('verifyUser.GIVE_ACCESS_EXPLANATION') }}
          </p>
          <Button
              :weight="scenario === 'forest-owner' ? 'primary' : 'tertiary'"
              class="mt-6"
              icon-after="arrow-right"
              @click="navigateToProperties"
          >
            {{ $t('verifyUser.GO_TO_ACCESS_CONTROL') }}
          </Button>
        </template>
        <template v-else>
          <GMAlert class="mt-3" icon="exclamation" type="warning">
            <div class="font-bold">Bruksrett må opprettes</div>
            <p class="mt-2">
              Bruksrett må opprettes for å kunne fortsette driften på eiendommen. Det kan du gjøre inne på innstillinger.
            </p>
            <Button
                class="my-6"
                icon-after="arrow-right"
                weight="primary"
                @click="navigateToProperties">
              {{ $t('verifyUser.GO_TO_ACCESS_CONTROL') }}
            </Button>

            <WhatIsRightOfUseHelp class="mb-2" />
          </GMAlert>
        </template>
        <div class="mt-6">
          <span class="font-bold">{{ $t('verifyUser.SEND_US_QUESTIONS') }}</span>
          <SupportContactInformationBox/>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import {GMAlert} from '@gm/components';
import SupportContactInformationBox from '~/components/modal/VerifyAccount/SupportContactInformationBox.vue';
import Button from '~/components/buttons/Button.vue';
import {AccountApi} from '~/gen/openapi/portalService';
import {ScenarioEnum, useDelegationOfAuthorityStore} from '~/stores/delegationOfAuthority';
import {useBankIDStore} from "~/stores/bankID";
import WhatIsRightOfUseHelp from '~/components/helpers/WhatIsRightOfUseHelp.vue';
import {useAccountService} from "~/services/account";

// export type Scenario = '1:1' | 'forest-owner' | 'company-owner' | 'not-real-owner' | undefined

export default defineComponent({
  components: {WhatIsRightOfUseHelp, Button, SupportContactInformationBox, GMAlert},
  props: {
    isFirstTimeVerification: {
      type: Boolean,
      default: () => true
    }
  },
  setup(props, {emit}) {
		const { $config, $axios } = useNuxtApp()
    const router = useRouter()
    const bankIDStore = useBankIDStore()
    const delegationOfAuthorityStore = useDelegationOfAuthorityStore();
		const accountsApi = new AccountApi(undefined, $config.public.apiBaseHost, $axios)
    const scenario = computed(() => delegationOfAuthorityStore.currentAccountScenario)
    const scenarioAccountId = computed(() => delegationOfAuthorityStore.currentAccountId)

    const { setActiveAccount, accountsQuery } = useAccountService()
    const { data: accounts } = accountsQuery()
    
    const settingsPageUrl = computed<string | undefined>(() => {
      if (scenarioAccountId.value == null || !scenario.value || [ScenarioEnum.FOREST_OWNER].includes(scenario.value)) {
        return `/my-account/user-access`
      }
      if ([ScenarioEnum.ONE_TO_ONE].includes(scenario.value)) {
        return `/my-account/fo/${scenarioAccountId.value}/property-legal-rights`
      }
      return undefined
    })
    const isCheckingScenarios = computed(() => delegationOfAuthorityStore.loading.detectingAccountScenario)
    
    const askForPoAQuery = computed(() => {
      return {
        firstName: bankIDStore.personInfoFromBankId?.FirstName,
        lastName: bankIDStore.personInfoFromBankId?.LastName,
        ssn: bankIDStore.userInfo?.nnin
      }
    })


    onMounted(async () => {
      // If this is a re-verification (user has been here at least once before), no further checks required.
      if (props.isFirstTimeVerification === false) {
        return
      }

      // Step 0 – get the accountPermissions the current logged-in user (aka "Contact") has access to
      const accountPermissions = (await accountsApi.accountGetAccountsByCurrentUser()).data
      if (!accountPermissions || !accountPermissions.length) {
        return
      }
      const primaryAccount = accountPermissions.find((account: any) => account.IsPrimaryAccount && account.Role === 1)
      if (!primaryAccount) {
        return
      }
      if (accounts.value?.length) {
        await setActiveAccount(primaryAccount.AccountId!, accounts)
      }
      await delegationOfAuthorityStore.detectCurrentUserAndAccountScenario(primaryAccount.AccountId!)
    })

    watch(() => scenario.value, (newScenario) => {
      if (!props.isFirstTimeVerification) {
        emit("enable-close-modal")
      }

      // Forest owners and not-a-real-owner must click the settings button to close modal.
      // Other scenarios may close wizard at this point
      if ((newScenario === ScenarioEnum.FOREST_OWNER || newScenario === ScenarioEnum.NOT_REAL_OWNER) && props.isFirstTimeVerification) {
        return
      }
      emit("enable-close-modal")
    }, { immediate: true })

    const navigateToProperties = async () => {
      if (scenario.value === ScenarioEnum.NOT_REAL_OWNER) {
        await router.push({path: `/my-account/fo/${scenarioAccountId.value}/property-legal-rights/delegate-powers-of-attorney`, query: askForPoAQuery.value})
      } else if (settingsPageUrl.value) {
        await router.push(settingsPageUrl.value)
      } else {
        await router.push('/my-account')
      }
      emit("close-wizard")
    }
    return {
      navigateToProperties,
      isCheckingScenarios,
      settingsPageUrl,
      scenario,
      scenarioAccountId,
      ScenarioEnum,
      askForPoAQuery,
    }
  }
})
</script>
