<template>
	<div>
		<Modal :show="showModal" :can-hide="false">
			<template #header>
				{{ $t('PLEASE_VERIFY_YOUR_DETAILS') }}
			</template>
			<template #default>
				<div
					v-if="!isSaving"
					class="py-2 px-4 bg-blue-lightest rounded text-sm text-blue-darkest leading-4"
				>
					{{ $t('VERIFY_DETAILS_DESC') }}
				</div>

				<BoxError v-if="hasError && errorMessage" class="mt-1">
					{{ errorMessage }}
				</BoxError>

				<div v-if="isSaving">
					<Loader>
						{{ $t('SAVING') }}
					</Loader>
				</div>
				<div v-else-if="isFetching" class="relative">
					<ContentLoader
						class="content-loader rounded"
						:height="277"
						:width="493"
						primary-color="#ececec"
						secondary-color="#dbdbdc"
					>
						<rect :x="0" :y="16" :width="493" :height="277" />
					</ContentLoader>
					<div class="absolute inset-0 flex items-center justify-center">
						<div class="text-gray-700">
							{{ $t('LOADING') }}
						</div>
					</div>
				</div>
				<form
					v-else-if="Object.keys(accountData).length"
					class="mt-4 border-2 border-blue-lightest py-3 px-4"
					@submit.stop.prevent="submit"
				>
					<div
						class="flex -mt-3 -mx-4 py-3 px-4 bg-blue-lightest text-blue-darkest"
					>
						<Avatar :username="accountData.Name" :size="24" class="mr-2" />
						<div>
							{{ accountData.Name }}
						</div>
					</div>

					<div class="mt-3">
						<label
							class="text-sm font-semibold"
							:class="{
								'text-gray-700': !showErrors || !inputError('email').hasError,
								'text-red-darkest': showErrors && inputError('email').hasError,
							}"
							:for="`modalVerifyUserDetails_${_uid}_email`"
						>
							{{ $t('EMAIL_ADDRESS') }}
						</label>
						<div
							v-if="
								!accountData.Email ||
								editing.includes('email') ||
								inputError('email').hasError
							"
						>
							<input
								:id="`modalVerifyUserDetails_${_uid}_email`"
								ref="emailInput"
								v-model="accountData.Email"
								class="input-text h-10 w-full"
								:class="{
									'input-text--error border-red-darker':
										showErrors && inputError('email').hasError,
								}"
								type="email"
								autocomplete="email"
								required
							/>
							<ul v-if="mailcheck.length">
								<li v-for="suggestion in mailcheck" :key="suggestion.full">
									<button
										class="flex px-3 py-2 w-full bg-yellow-lightest text-yellow-darkest text-left rounded"
										type="button"
										@click="accountData.Email = suggestion.full"
									>
										<svg-icon class="mt-1 mr-1 w-4 h-4 fill-current text-yellow" name="question-16" />
										<span>
											{{ $t('DID_YOU_MEAN') }}
											<strong>{{ suggestion.full }}</strong
											>?
										</span>
									</button>
								</li>
							</ul>

							<ul v-if="showErrors && inputError('email').hasError">
								<li
									v-for="error in inputError('email').errors"
									:key="error.message"
									class="flex mt-1 py-2 px-3 w-full bg-red-lightest text-red-darkest rounded"
								>
									<svg-icon class="flex-shrink-0 mt-1 mr-1 w-4 h-4 fill-current text-red-lighter" name="error" />
									<span>
										{{ error.message }}
									</span>
								</li>
							</ul>
						</div>
						<div v-else class="flex pb-2">
							<div class="mr-2 text-xl leading-6">
								{{ accountData.Email }}
							</div>
							<button
								class="transition duration-300 inline-flex items-center border border-blue-lightest hocus:border-blue-lighter px-2 py-1 bg-blue-lightest h-6 text-xs text-blue-darkest font-semibold leading-3 uppercase rounded hocus:shadow"
								type="button"
								@click.stop.prevent="edit('email', 'emailInput')"
							>
								{{ $t('EDIT') }}
								<span class="sr-only">
									{{ $t('EMAIL_ADDRESS') }}
								</span>
							</button>
						</div>
					</div>

					<div class="mt-3">
						<label
							class="text-sm font-semibold"
							:class="{
								'text-gray-700': !showErrors || !inputError('mobile').hasError,
								'text-red-darkest': showErrors && inputError('mobile').hasError,
							}"
							:for="`modalVerifyUserDetails_${_uid}_mobilePhone`"
						>
							{{ $t('MOBILE_PHONE_NUMBER') }}
						</label>
						<div
							v-if="
								editing.includes('mobilePhone') || inputError('mobile').hasError
							"
						>
							<InputPhoneNumber
								:id="`modalVerifyUserDetails_${_uid}_mobilePhone`"
								v-model="mobileNumberAsObject"
								:disabled="isSaving"
								:error="inputError('mobile').hasError ? $t('ERROR_INVALID_PHONE_NUMBER') : undefined"
								name="mobile"
							/>
							<ul v-if="showErrors && inputError('mobile').hasError">
								<li
									v-for="error in inputError('mobile').errors"
									:key="error.message"
									class="flex mt-1 py-2 px-3 w-full bg-red-lightest text-red-darkest rounded"
								>
									<svg-icon class="flex-shrink-0 mt-1 mr-1 w-4 h-4 fill-current text-red-lighter" name="error" />
									<span>
										{{ error.message }}
									</span>
								</li>
							</ul>
						</div>
						<div v-else class="flex pb-2">
							<div class="mr-2 text-xl leading-6">
								{{ mobileNumberDisplay }}
							</div>
							<button
								class="transition duration-300 inline-flex items-center border border-blue-lightest hocus:border-blue-lighter px-2 py-1 bg-blue-lightest h-6 text-xs text-blue-darkest font-semibold leading-3 uppercase rounded hocus:shadow"
								type="button"
								@click.stop.prevent="edit('mobilePhone', 'mobilePhoneInput')"
							>
								{{ $t('EDIT') }}
								<span class="sr-only">
									{{ $t('MOBILE_PHONE_NUMBER') }}
								</span>
							</button>
						</div>
					</div>

					<div class="mt-3">
						<div
							:id="`modalVerifyUserDetails_${_uid}_bankAccountNumberLabel`"
							class="text-sm font-semibold"
							:class="{
								'text-gray-700':
									!showErrors || !inputError('bankAccountNumber').hasError,
								'text-red-darkest':
									showErrors && inputError('bankAccountNumber').hasError,
							}"
						>
							{{ $t('BANK_ACCOUNT_NUMBER') }}
						</div>
						<div v-if="false && editing.includes('bankAccountNumber')">
							<input
								:id="`modalVerifyUserDetails_${_uid}_bankAccountNumber`"
								ref="bankAccountNumberInput"
								v-model="bankAccountNumber"
								v-mask="'#### ## #####'"
								:aria-labelledby="`modalVerifyUserDetails_${_uid}_bankAccountNumberLabel`"
								class="input-text h-10 w-36"
								:class="{
									'input-text--error border-red-darker':
										showErrors && inputError('bankAccountNumber').hasError,
								}"
								type="text"
								inputmode="numeric"
								autocomplete="false"
								data-lpignore="true"
								required
							/>
							<ul v-if="showErrors && inputError('bankAccountNumber').hasError">
								<li
									v-for="error in inputError('bankAccountNumber').errors"
									:key="error.message"
									class="flex mt-1 py-2 px-3 w-full bg-red-lightest text-red-darkest rounded"
								>
									<svg-icon class="flex-shrink-0 mt-1 mr-1 w-4 h-4 fill-current text-red-lighter" name="error" />
									<span>
										{{ error.message }}
									</span>
								</li>
							</ul>
						</div>
						<div v-else class="flex pb-2">
							<div class="mr-2 text-xl leading-6">
								<div
									:class="{
										'italic text-gray-700': !accountData.BankAccountNumber,
									}"
								>
									{{
										accountData.BankAccountNumber
											? formatBankAccountNumber(accountData.BankAccountNumber)
											: $t('NOT_REGISTERED')
									}}
								</div>

								<div class="flex text-sm text-blue-700 leading-4">
									<svg-icon class="flex-shrink-0 mt-0.5 mr-1 w-3 h-3 fill-current text-blue-500" name="info" />

									{{
										accountData.BankAccountNumber
											? $t(
												'IF_YOU_WISH_TO_CHANGE_YOUR_BANK_ACCOUNT_NUMBER_PLEASE_CONTACT_ACCOUNTING'
											)
											: $t(
												'IF_YOU_WISH_TO_ADD_YOUR_BANK_ACCOUNT_NUMBER_PLEASE_CONTACT_ACCOUNTING'
											)
									}}
								</div>
							</div>
							<!-- <button
								class="
									transition
									duration-300
									inline-flex
									items-center
									border
									border-blue-lightest
									hocus:border-blue-lighter
									px-2
									py-1
									bg-blue-lightest
									h-6
									text-xs
									text-blue-darkest
									font-semibold
									leading-3
									uppercase
									rounded
									hocus:shadow
								"
								type="button"
								@click.stop.prevent="edit('bankAccountNumber', 'bankAccountNumberInput')"
							>
								{{ $t('EDIT') }}
								<span class="sr-only">
									{{ $t('BANK_ACCOUNT_NUMBER') }}
								</span>
							</button> -->
						</div>
					</div>

					<div class="mt-3">
						<div class="text-sm text-gray-700 font-semibold">
							{{ $t('ACCOUNTANT') }}
						</div>
						<div class="flex py-2">
							<div
								v-if="accountData.AccountantId"
								class="mr-2 text-xl leading-6"
							>
								{{ accountData.AccountantName }}
							</div>
							<button
								class="transition duration-300 inline-flex items-center border border-blue-lightest hocus:border-blue-lighter px-2 py-1 bg-blue-lightest h-6 text-xs text-blue-darkest font-semibold leading-3 uppercase rounded hocus:shadow"
								type="button"
								@click.stop.prevent="showAccountantsModal = true"
							>
								{{ accountData.AccountantId ? $t('REPLACE') : $t('ADD') }}
								<span class="sr-only">
									{{ $t('ACCOUNTANT') }}
								</span>
							</button>
							<button
								v-if="accountData.AccountantId"
								class="transition duration-300 inline-flex items-center ml-2 border border-red-lightest hocus:border-red-lighter px-2 py-1 bg-red-lightest h-6 text-xs text-red-darkest font-semibold leading-3 uppercase rounded hocus:shadow"
								type="button"
								@click.stop.prevent="removeAccountant"
							>
								{{ $t('REMOVE') }}
								<span class="sr-only">
									{{ $t('ACCOUNTANT') }}
								</span>
							</button>
						</div>
					</div>

					<button class="sr-only" type="submit" :disabled="isFetching">
						{{
							!dataIsChanged
								? $t('CONFIRM_DETAILS')
								: $t('CONFIRM_CHANGES_AND_SAVE')
						}}
					</button>
				</form>
			</template>
			<template #footer>
				<div class="flex flex-wrap w-full">
					<div class="w-full md:w-1/2">
						<button
							class="btn btn--primary w-full"
							type="submit"
							:disabled="isFetching || isSaving"
							@click="submit"
						>
							{{
								!dataIsChanged
									? $t('CONFIRM_DETAILS')
									: $t('CONFIRM_CHANGES_AND_SAVE')
							}}
						</button>
					</div>
					<div class="w-full md:w-1/2" v-if="!isSaving">
						<button
							v-if="dataIsChanged || editing.length"
							class="btn btn--link w-full"
							type="button"
							@click="reset"
						>
							{{ $t('RESET_CHANGES') }}
						</button>
            <button
                v-else
                class="btn btn--link w-full"
                type="button"
                @click="userStore.trigLogout()"
            >
              {{ $t('LOG_OUT') }}
            </button>
					</div>
				</div>
			</template>
		</Modal>

		<ModalChangeAccountant
			:show="showAccountantsModal"
			:active-accountant-id="accountData.AccountantId"
			:emit-changes="true"
			@update:modelValue="changeAccountant"
			@close="showAccountantsModal = false"
		/>
	</div>
</template>
<script>
import {GMAvatar as Avatar} from '@gm/components'
import {mask} from 'vue-the-mask'
import cloneDeep from 'lodash/cloneDeep'
import {ContentLoader} from 'vue-content-loader'
import formatBankAccountNumber from '@/helpers/formatBankAccountNumber'
import mailcheck from '@/helpers/mailcheck'
import setFocus from '@/helpers/setFocus'
import validateEmailAddress from '@/helpers/validateEmailAddress'
import validatePhoneNumber from '@/helpers/validatePhoneNumber'

import BoxError from '@/components/BoxError.vue'
import Loader from '@/components/Loader.vue'
import Modal from '@/components/Modal.vue'
import ModalChangeAccountant from '@/components/modal/ChangeAccountant.vue'
import InputPhoneNumber from "~/components/form/InputPhoneNumber.vue";
import {useUserStore} from "~/stores/user";
import {useAccountService} from "~/services/account";

const STATES = {
	HIDE: 'hide',
	SHOW: 'show',
	FETCHING: 'fetching',
	ERROR: 'error',
	SAVING: 'saving',
}

export default {
	components: {
		Avatar,
		BoxError,
		ContentLoader,
		Loader,
		Modal,
		ModalChangeAccountant,
		InputPhoneNumber,
	},
	directives: { mask },
	setup() {
    const { accountsQuery, primaryForestOwnerAccountQuery, primaryForestOwnerAccountPermissionModel, saveAccount } = useAccountService();
    const { data: accounts } = accountsQuery()
    const permissionModel = primaryForestOwnerAccountPermissionModel(accounts)
    const { data: primaryForestOwnerAccount, error: errorFetchingPrimaryForestOwner } = primaryForestOwnerAccountQuery(permissionModel)
		const userStore = useUserStore()
		return {
      accounts,
      errorFetchingPrimaryForestOwner,
      primaryForestOwnerAccount,
			userStore,
      saveAccount,
		}
	},
	data() {
		return {
			accountData: {},
			accountDataOld: {},
			editing: [],
			errorMessage: '',
			showAccountantsModal: false,
			state: STATES.HIDE,
		}
	},
	computed: {
		bankAccountNumber: {
			get() {
				return this.accountData.BankAccountNumber
			},
			set(value) {
				// The mask used as directive returns masked result
				// We need to replace the spaces before updating the value
				this.accountData.BankAccountNumber = value.replace(/\s/g, '')
			},
		},
		dataIsChanged() {
			const accountData = this.accountData
			return JSON.stringify(accountData) !== JSON.stringify(this.accountDataOld)
		},
		hasError() {
			return this.state === STATES.ERROR
		},
		isFetching() {
			return this.state === STATES.FETCHING
		},
		isSaving() {
			return this.state === STATES.SAVING
		},
		mailcheck() {
			const email = this.accountData.Email
			if (!validateEmailAddress(email)) {
				return []
			}
			return mailcheck(email)
		},
		mobileNumber: {
			get() {
				return this.accountData.Mobile.replace(/\s/g, '')
			},
			set(value) {
				this.accountData.Mobile = value.replace(/\s/g, '')
			},
		},
		mobileNumberAsObject: {
			get() {
				if (this.accountData.Mobile?.substring(0, 1) === '+') {
					return {
						phoneNumber: this.accountData.Mobile.substring(3),
						countryCode: this.accountData.Mobile.substring(0, 3),
					}
				} else {
					return {
						phoneNumber: this.accountData.Mobile,
						countryCode: '+47',
					}
				}
			},
			set(value) {
				this.accountData.Mobile = value.countryCode + value.phoneNumber
			},
		},
		mobileNumberDisplay() {
			// Reverse the phone number to normalize it. Last 8 digits should follow a format, whatever is in front is not part of it.
			const mobileNumber = this.mobileNumber.split("").reverse().join("")
			const segments = []

			// Last 3 digits
			segments.push(mobileNumber.substring(0, 3))
			// 2 digits in the "middle" of the number
			segments.push(mobileNumber.substring(3, 5))
			// 3 more digits in the front
			segments.push(mobileNumber.substring(5, 8))
			// The rest (if any). Could be country code, with 00 or + in front.
			segments.push(mobileNumber.substring(8))

			// Reverse it back to normal.
			return segments.join(' ').split("").reverse().join("")
		},
		showErrors() {
			return this.hasError
		},
		showModal() {
			return this.verificationTimedOut && this.state !== STATES.HIDE
		},
		user() {
		},
		validationErrors() {
			const accountData = this.accountData
			const email = accountData.Email
			const mobile = accountData.Mobile
			const bankAccountNumber = accountData.BankAccountNumber
			const errors = []

			// Validate email
			if (!validateEmailAddress(email)) {
				errors.push({
					field: 'email',
					message: this.$t('ERROR_INVALID_EMAIL'),
				})
			}

			// Validate phone number
			if (!validatePhoneNumber(mobile)) {
				errors.push({
					field: 'mobile',
					message: this.$t('ERROR_INVALID_PHONE_NUMBER'),
				})
			}

			/* if (!validateBankAccountNumber(bankAccountNumber)) {
				errors.push({
					field: 'bankAccountNumber',
					message: this.$t('ERROR_INCORRECT_BANK_ACCOUNT_FORMAT')
				})
			} */

			return errors
		},
		verificationTimedOut() {
			const userData = this.userStore.userData
			const lastVerified = userData && userData.lastVerified

			if (!lastVerified) {
				return false
			}

      const lastVerifiedDate = new Date(lastVerified)
      lastVerifiedDate.setMonth(lastVerifiedDate.getMonth() + 6)
      const now = new Date()

      return lastVerifiedDate < now
		},
	},
	watch: {
		accounts: {
			handler: 'mount',
			immediate: true,
		},
	},
	methods: {
		formatBankAccountNumber,
		changeAccountant(data) {
			for (const key in data) {
				this.accountData[key] = data[key]
			}
		},
		close() {
			this.$emit('close')
		},
		async edit(field, ref) {
			this.editing.push(field)
			await this.$nextTick()

			const element = this.$refs[ref]
			if (element) {
				setFocus(element)
			}
		},
		mount() {
			if (this.verificationTimedOut) {
				this.getData()
			}
		},
		async getData() {
			// Should only be one account that is connected w/your user, and has admin access (Role: 1)
			const accountData = cloneDeep(this.primaryForestOwnerAccount)

			// If no account, close modal since there is no data to validate
			if (!this.primaryForestOwnerAccount) {
				this.state = STATES.HIDE
				return
			}

			this.state = STATES.FETCHING

      // We need to make sure that all the required fields are present
      // or else we will have reactivity issues when data is added
      if (!accountData.Email) {
        accountData.Email = ''
        this.editing.push('email')
      }
      if (!accountData.Mobile) {
        accountData.Mobile = ''
        this.editing.push('mobilePhone')
      }
      if (!accountData.BankAccountNumber) {
        accountData.BankAccountNumber = ''
        this.editing.push('bankAccountNumber')
      }

      this.accountData = accountData
      this.accountDataOld = cloneDeep(accountData)
      this.state = STATES.SHOW

      if (this.errorFetchingPrimaryForestOwner) {
				this.state = STATES.ERROR
				this.errorMessage = this.errorFetchingPrimaryForestOwner
			}
		},
		inputError(field) {
			const errors = this.validationErrors.filter(
				(error) => error.field === field
			)
			return {
				errors,
				hasError: !!errors.length,
			}
		},
		removeAccountant() {
			this.accountData.AccountantId = null
			this.accountData.AccountantName = ''
		},
		reset() {
			this.accountData = cloneDeep(this.accountDataOld)
			this.editing = []
		},
		async submit() {
			if (this.validationErrors.length) {
				this.state = STATES.ERROR
				return
			}

			this.state = STATES.SAVING

      this.saveAccount({ Account: this.accountData }, {
        onSuccess: async () => {
          try {
            await this.userStore.verified()
          } catch (error) {
            this.errorMessage = error.toString()
            this.state = STATES.ERROR
          }
          this.state = STATES.hide
        },
        onError: (error) => {
          console.error(error)
          this.errorMessage = error.toString()
          this.state = STATES.ERROR
        }
      })
		},
	},
}
</script>
