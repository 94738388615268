<template>
	<div>
		<p>{{ $t('verifyUser.INCORRECT_INFORMATION') }}</p>

		<SupportContactInformationBox class="my-3" />

		<div class="flex flex-col items-stretch gap-2">
			<Button
				class="justify-center col-span-2"
				@click="$emit('go-back')"
			>
				{{ $t('verifyUser.GO_BACK_TO_VERIFICATION') }}
			</Button>
			<Button v-if="!isForcedToVerify" @click="$emit('postpone-verification-click')" weight="tertiary" class="justify-center">{{ $t('verifyUser.POSTPONE_VERIFICATION') }}</Button>
		</div>

	</div>

</template>

<script>
import Button from "~/components/buttons/Button.vue";
import SupportContactInformationBox from "~/components/modal/VerifyAccount/SupportContactInformationBox";

export default {
	components: {
		SupportContactInformationBox,
		Button
	},
	props: {
		isForcedToVerify: {
			type: Boolean,
			default: () => false
		},
	}
}
</script>
