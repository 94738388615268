<template>
	<div class="constrained flex-center flex-1">
		<loader icon="lock-open" v-bind:text="$t('LOGGING_IN')"></loader>
	</div>
</template>

<script>
import Loader from '~/components/Loader.vue'

export default {
	name: 'login-loader',
	components: {
		Loader,
	},
}
</script>
