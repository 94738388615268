<template>
	<modal :show="!localeStore.locale" :canHide="false">
		<template #header>
			<span lang="no">Vennligst velg språk</span>
			<span> / </span>
			<span lang="no">Please choose language</span>
		</template>
		<template #default>
			<p lang="no">
				🇳🇴 Vennligst velg språket du ønsker å bruke i denne løsningen. Du kan
				endre dette valget senere i «Min konto». Valget lagres lokalt i
				nettleseren. Hvis du tilbakestiller nettleseren, bytter nettleser, eller
				bytter datamaskin kan du bli nødt til å gjøre dette valget igjen senere.
			</p>
			<p lang="en">
				🇬🇧 Please choose the language you wish to use for this application. You
				can change this at any time in "My account". Your selection will be
				saved locally in your web browser. If you reset your browser, change
				browser, or change computer you may be required to make this selection
				again.
			</p>
			<div class="border-4 border-blue-lightest mt-4 bg-white">
				<div
					v-for="language in localeStore.localeOptions"
					:key="language.value"
					class="m-1"
				>
					<button
						type="button"
						class="block p-3 w-full bg-cyan-lightest hocus:bg-cyan-lighter rounded"
						@click.stop.prevent="changeLanguage(language.value)"
					>
						{{ language.flag }} {{ language.name }}
					</button>
				</div>
			</div>
		</template>
	</modal>
</template>
<script>
import Modal from '@/components/Modal.vue'
import {useLocaleStore} from "~/stores/locale";

export default {
	components: { Modal },
	setup() {
		return {
			localeStore: useLocaleStore(),
		}
	},
	methods: {
		changeLanguage(value) {
			if (!value) {
				return
			}

			// No ajax request here som just a (presumably) immediate state update
			// The state is saved in localhost, so should remain theoretically 'forever'
			this.localeStore.SET_LANG(value)
			// Norwegian is default lang so no need to refresh?
			if (value !== 'no') {
				window.location.reload()
			}
		},
	},
}
</script>
