<template>
	<div
		id="app"
		:class="{
			'sticky-footer--highlight': !isLoggedIn,
			'sticky-footer--backdrop': !isLoggedIn && !isLoggingIn,
		}"
		class="sticky-footer"
	>
    <VueQueryDevtools v-if="$config.public.environment?.toString()?.includes('DEV')" />
    <!-- @gm/components globals. Responsible for displaying any confirmations issued by other components -->
    <GMConfirmations class="absolute whitespace-pre" style="z-index: 99999;"/>
		<div class="sticky-footer__main">
      <!-- Banner for info notices, maintenance warnings etc -->
      <TopInfoBanner v-if="shouldDisplayTopInfoBanner" :color="topInfoBannerColor" />

      <TopMenu/>

			<!-- @gm/components globals. Responsible for displaying any notifications issued by other components -->
			<GMNotifications
        offset-right="10"
				class="absolute z-40 right-10 top-10"
			/>
			<!-- For Tailwind Jit purge to work we need to hardcode the classes here so they're not stripped away -->
			<div class="hidden top-18 right-18 bottom-18 left-18"></div>


			<Banner :key="key"/>

			<!-- MAINTENANCE NOTIFICATION -->
			<div
				v-if="$config.public.features.manualMaintenanceWarning || (helpTexts && helpTexts.DisplayMaintenanceMessage)"
				class="mt-4 constrained"
			>
				<MaintenanceWarning/>
			</div>

      <GMAlert type="error" class="w-full text-center" v-if="errorFetchingAccounts">
        {{$t('ERROR_COULD_NOT_LOAD_ACCOUNTS')}}
      </GMAlert>
      <GMAlert type="error" class="w-full text-center" v-else-if="errorFetchingActiveAccount">
        {{$t('ERROR_COULD_NOT_LOAD_ACCOUNT')}}
      </GMAlert>

			<!-- MAIN CONTENT -->
			<div
				v-if="isLoggedIn"
				class="flex flex-1 nuxt-page-wrapper"
				style="flex-basis: auto"
			>
				<slot />
			</div>

			<!-- LOGIN -->
			<transition class="flex" mode="out-in" name="transition--slide-up">
				<LoginLoader v-if="isLoggingIn" key="loader"/>
				<Login v-else-if="!isLoggedIn" key="login"/>
			</transition>

			<!-- MODALS -->
			<ModalLanguageSelect/>
			<ModalWelcome/>
			<!-- https://dev.azure.com/GlommenMjosen/Portaler%20og%20SBL-app/_workitems/edit/1594/ -->
			<!-- Two different types of "verify-account" modals -->
			<!-- They both serve the same purpose: user must update their details every once in a while -->
			<!-- VerifyAccountWizard is the "new" flow, with BankID login -->
			<!-- ModalVerifyUserDetails is the old modal, only used for certain users, which has been excluded from bankid-verification -->
			<VerifyAccountWizard v-if="!isFetchingAccounts && !errorFetchingAccounts && isLoggedIn && userShouldVerifyWithBankID"/>
			<ModalVerifyUserDetails v-else-if="!isFetchingAccounts && !errorFetchingAccounts && isLoggedIn && !userShouldVerifyWithBankID"/>
			<ModalPrivacyInformation/>

		</div>
		<div
			:class="{
				'sticky-footer__footer--dark': !isLoggedIn && !isLoggingIn,
			}"
			class="sticky-footer__footer"
		>
			<Footer/>
		</div>
	</div>
</template>

<script>
import {GMAlert, GMConfirmations, gmGlobal, GMNotifications, notify} from "@gm/components";
import {mapState} from "pinia";
import Banner from '@/components/banner/Switcher.vue'
import Footer from '@/components/Footer.vue'
import Login from '@/components/Login.vue'
import LoginLoader from '@/components/LoginLoader.vue'
import MaintenanceWarning from '@/components/MaintenanceWarning.vue'
import ModalLanguageSelect from '@/components/modal/LanguageSelect.vue'
import ModalPrivacyInformation from '@/components/modal/PrivacyInformation.vue'
import ModalWelcome from '@/components/modal/Welcome.vue'
import TopMenu from '@/components/TopMenu.vue'
import VerifyAccountWizard from "@/components/modal/VerifyAccount/Wizard";
import {useUserStore} from "~/stores/user";
import {useOrderStore} from "~/stores/order";
import {useDelegationOfAuthorityStore} from "~/stores/delegationOfAuthority";
import {VueQueryDevtools} from "@tanstack/vue-query-devtools";
import {useHelpTextsService} from "~/services/helpTexts";
import {useAccountService} from "~/services/account";
import {ROLE_FOREST_OWNER} from "~/store/constants/customer";

export default {
	components: {
    GMAlert,
    VueQueryDevtools,
		VerifyAccountWizard,
		Banner,
		Footer,
		Login,
		LoginLoader,
		MaintenanceWarning,
		ModalLanguageSelect,
		ModalPrivacyInformation,
		ModalWelcome,
		TopMenu,
		GMNotifications,
		GMConfirmations,
	},
	setup() {
    useHead({
      bodyAttrs: {
        class: 'light',
      },
    })
    const { $config } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const route = useRoute()
    const delegationOfAuthorityStore = useDelegationOfAuthorityStore()
    const { helpTexts } = useHelpTextsService()
		const userStore = useUserStore()
    const orderStore = useOrderStore()
    const { setActiveAccount, accountRole, accountsQuery, primaryForestOwnerAccountPermissionModel, primaryForestOwnerAccountQuery, getUserShouldVerify, errorFetchingActiveAccount } = useAccountService()
    const { data: accounts, isFetching: isFetchingAccounts, error: errorFetchingAccounts } = accountsQuery()
    const permissionModel = primaryForestOwnerAccountPermissionModel(accounts)
    const { data: primaryForestOwnerAccount } = primaryForestOwnerAccountQuery(permissionModel)
    const userShouldVerifyWithBankID = computed(() => getUserShouldVerify(primaryForestOwnerAccount))

    watch(
        () => route.params.accountId,
        (accountId) => {
          if (accountId) {
            setActiveAccount(accountId, accounts)?.then(() => {
              if (accountRole(accountId, accounts) === ROLE_FOREST_OWNER) {
                delegationOfAuthorityStore.detectCurrentUserAndAccountScenario(accountId);
              }
            });
          }
        },
        {immediate: true}
    );

    watch(
        () => [route.params.orderId, route.params.contractId],
        (id) => {
          const idValue = id.find((value) => !!value)
          if (idValue) {
            orderStore.setActiveOrderId(idValue)
          } else if (!idValue && !!orderStore.activeOrderId) {
            orderStore.setActiveOrderId(null) // clear active order
          }
        },
        {immediate: true}
    );

    const topInfoBannerColor = computed(() => {
      const color = runtimeConfig.public.topInfoBannerColor
      if (color?.length && ['yellow', 'green', 'red', 'blue'].includes(color.toLowerCase())) {
        return color.toLowerCase()
      }
    })

    const shouldDisplayTopInfoBanner = computed(() => {
      const isEnabled = $config.public.features.topInfoBanner
      if (isEnabled) {
        const requiredUrlPartsString = $config.public.topInfoBannerRequiredUrlParts
        if (requiredUrlPartsString?.length) {
          const requiredUrlParts = requiredUrlPartsString.toLowerCase().replace(' ', '').split(',')
          return requiredUrlParts.find((part) => route.fullPath.toLowerCase().includes(part))
        }
        return true
      }
      return false
    })

		return {
      delegationOfAuthorityStore,
      helpTexts,
			userStore,
      topInfoBannerColor,
      shouldDisplayTopInfoBanner,
      userShouldVerifyWithBankID,
      isFetchingAccounts,
      accounts,
      errorFetchingAccounts,
      errorFetchingActiveAccount,
		}
	},
	computed: {
		...mapState(useUserStore, ['isCheckingLoginStatus', 'isLoggedIn', 'isLoggingIn']),
		key() {
			return this.$route.path.substring(0, 40)
		},
	},
	watch: {
		isCheckingLoginStatus() {
			// If the login status is being checked, and this is taking more than a few milliseconds
			// the notification will display, it will remain infinitely (duration: -1) untill the login status check
			// state changes to negative, at which point it is re-set
			// Docs: https://github.com/euvl/vue-notification

			setTimeout(() => {
				if (this.isCheckingLoginStatus) {
          notify(this.$t('CHECKING_LOGIN').toString(), {
            type: 'info',
            icon: 'clock-outline',
            loading: true,
            duration: 0,
          })
				} else {
          gmGlobal().notificationsReset()
				}
			}, 500)
		},
		isLoggedIn() {
			this.fetch()
		},
	},
	mounted() {
		this.fetch()
		/*		document.addEventListener("visibilitychange", () => {
  		if( document.visibilityState ) {
  			this.checkLogin()
  		}
		}) */
	},
	methods: {
		checkLogin(force = false) {
			this.userStore.checkLogin(force)
		},
		fetch() {
			if (this.$route.query.reauth) {
				this.$router.replace({
					query: {
						reauth: undefined,
					},
				})

				this.checkLogin(true)
			}
		},
	},
}
</script>

<style>
/*.nuxt-page-wrapper {
	overflow: hidden;
}
*/
.container {
	width: 100%;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  width: 100vw;
	transition: all 0.3s ease-in-out;
}

/* entering start */
.slide-right-enter-from,
.slide-left-leave-to {
	opacity: 0;
	transform: translate(-100%, 0);
}

.slide-left-enter-from,
.slide-right-leave-to {
	opacity: 0;
	transform: translate(100%, 0);
}
</style>
